// https://kth7zff1sg.execute-api.us-east-1.amazonaws.com/dev/ || https://api.firstbatch.xyz/
// https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/ || https://api2.firstbatch.xyz/
// https://0a8dia6t0c.execute-api.us-east-1.amazonaws.com/dev/ || https://api3.firstbatch.xyz/

export const setSessionURL = () => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? 'https://api2.firstbatch.xyz/2/set_session'
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        'https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/set_session'
}

export const connectTelegramURL = () => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? 'https://api2.firstbatch.xyz/2/connect_telegram'
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        'https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/connect_telegram'
}

export const visibilityWebsummitURL = () => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? 'https://api2.firstbatch.xyz/2/visibility-websummit'
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        'https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/visibility-websummit'
}

export const getTelegramMatchesURL = () => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? 'https://api2.firstbatch.xyz/2/get_telegram_matches'
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        'https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/get_telegram_matches'
}

export const checkUserURL = () => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? 'https://api2.firstbatch.xyz/2/check_user'
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        'https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/check_user'
}
