import { createContext, useState, useEffect } from 'react'
import { Strategy, ZkIdentity } from '@zk-kit/identity'
import {
  useConnect,
  useAccount,
  useDisconnect,
  useNetwork,
  useSignMessage,
} from 'wagmi'

import {
  checkUserURL,
  connectTelegramURL,
  setSessionURL,
  getTelegramMatchesURL,
  visibilityWebsummitURL,
} from '../helpers/endpoints'

let tId

const isLogsActive = process.env.NODE_ENV === 'development'

const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const { connect, connectors } = useConnect({
    chainId: 1,
  })
  const account = useAccount()
  const signMessage = useSignMessage({
    message: `Creating ZK Identity with ${account?.address}`,
    onSuccess(data) {
      isLogsActive && console.log('Success', data)
      createIdentity({ sig: data, type: 'create' })
    },
    onError(error) {
      console.log('Error', error)
      setIsPending(false)
    },
  })
  const network = useNetwork()
  const { disconnect } = useDisconnect({
    onSuccess() {
      isLogsActive && console.log('disconnected')
    },
  })
  const [wConnector, setWConnector] = useState(null)
  const [wChain, setWChain] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const [matches, setMatches] = useState(null)
  const [errMsg, setErrMsg] = useState('')
  const [isVisible, setIsVisible] = useState(null)
  const [isReq, setIsReq] = useState(false)
  const [idComm, setIdComm] = useState(null)
  const [userExists, setUserExists] = useState(null)

  const createIdentity = args => {
    setIsPending(true)
    const { type, sig } = args

    if (type === 'init') {
      isLogsActive && console.log('createIdentity with =>', args)
      tId = args.telegramId
      signMessage.signMessage()
    } else {
      isLogsActive && console.log('createIdentity with =>', args)
      const identity = new ZkIdentity(Strategy.MESSAGE, sig)
      const identityCommitment = identity.genIdentityCommitment().toString()
      setIdComm(identityCommitment)
      createSession({ identityCommitment, sig })
    }
  }

  const createSession = async args => {
    isLogsActive && console.log('createSession invoked wiht =>', args)
    const msgBuffer = new TextEncoder().encode(args.identityCommitment)
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const h_comm = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
    const sessionId = await fetch(setSessionURL(), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ sig: args.sig, wid: account?.address, h_comm }),
    })
      .then(response => response.json())
      .then(data => {
        isLogsActive && console.log('createSession res =>', data)
        return data.message.success
          ? data.message.session_id
          : data.message.success
      })
      .catch(err => {
        console.log('createSession err =>', err)
        setErr('Network Error, please try again later')
        return false
      })

    if (sessionId !== false) {
      localStorage.setItem('session', sessionId)
      checkUserState({ id_commitment: args.identityCommitment })
    }
  }

  const checkUserState = async args => {
    isLogsActive && console.log('checkUserState invoked with =>', args)

    const { id_commitment } = args

    const userState = await fetch(checkUserURL(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('session'),
      },
      body: JSON.stringify({ id_commitment }),
    })
      .then(response => response.json())
      .then(data => {
        isLogsActive && console.log('checkUserState res =>', data)
        return data.message
      })
      .catch(err => {
        console.log('checkUserState err =>', err)
        return null
      })

    isLogsActive && console.log('userState =>', userState)

    if (userState) {
      connectTelegram({ id_commitment })
    } else {
      setUserExists(false)
      setIsPending(false)
    }
  }

  const connectTelegram = args => {
    isLogsActive && console.log('connectTelegram invoked with =>', args)

    const { id_commitment } = args

    fetch(connectTelegramURL(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('session'),
      },
      body: JSON.stringify({ id_commitment, telegram_id: tId.substring(1) }),
    })
      .then(response => response.json())
      .then(data => {
        isLogsActive && console.log('connectTelegram res =>', data)
        data.code === 200 &&
          data.message === 'Telegram ID added' &&
          getTelegramMatches({ id_commitment })
      })
      .catch(err => {
        console.log('connectTelegram err =>', err)
        setErr('Network Error, please try again later')
        return err
      })
  }

  const getTelegramMatches = args => {
    isLogsActive && console.log('getTelegramMatches invoked with =>', args)

    const { id_commitment } = args

    fetch(getTelegramMatchesURL(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('session'),
      },
      body: JSON.stringify({ id_commitment, telegram_id: tId.substring(1) }),
    })
      .then(response => response.json())
      .then(data => {
        isLogsActive && console.log('getTelegramMatches res =>', data)
        setIsPending(false)
        setIsVisible(data.state)
        setMatches(Object.entries(data.users))
        setUserExists(true)
      })
      .catch(err => {
        console.log('getTelegramMatches err =>', err)
        setErr('Network Error, please try again later')
        return err
      })
  }

  const switchVisibility = () => {
    if (isReq) return

    console.log('switchVisibility invoked!')

    setIsReq(true)

    fetch(visibilityWebsummitURL(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('session'),
      },
      body: JSON.stringify({ state: !isVisible, id_commitment: idComm }),
    })
      .then(response => response.json())
      .then(data => {
        isLogsActive && console.log('switchVisibility res =>', data)
        setIsReq(false)
        data.code === 200 &&
          data.message === 'State changed!' &&
          setIsVisible(!isVisible)
      })
      .catch(err => {
        setIsReq(false)
        setErr('Network Error, please try again later')
        console.log(err)
      })
  }

  const setErr = err => {
    setIsPending(false)
    setErrMsg(err)
    setTimeout(() => {
      setErrMsg('')
    }, 5000)
  }

  useEffect(() => {
    if (account?.connector?.id) {
      setWConnector(account.connector.id)
    }
  }, [account])

  useEffect(() => {
    setWChain(network.chain?.id)
  }, [network])

  return (
    <AppContext.Provider
      value={{
        connect,
        connectors,
        account,
        disconnect,
        wConnector,
        wChain,
        isPending,
        signMessage,
        createIdentity,
        matches,
        errMsg,
        switchVisibility,
        isVisible,
        userExists,
      }}>
      {children}
    </AppContext.Provider>
  )
}

export default AppContext
