import { useState, useEffect, useContext } from 'react'
import { VscCopy } from 'react-icons/vsc'
import { IoCheckmark } from 'react-icons/io5'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { Puff } from 'react-loading-icons'
import AppContext from '../../context/AppContext'
import Layout from '../Layout'
import useWindowDimensions from '../../hooks/useWindowDimensions'

export default function Synergy() {
  const {
    createIdentity,
    matches,
    errMsg,
    isPending,
    switchVisibility,
    isVisible,
    userExists,
  } = useContext(AppContext)
  const { height, width } = useWindowDimensions()
  const [telegramId, setTelegramId] = useState('')
  const [formError, setFormError] = useState({
    isError: false,
    errorMessage: '',
  })
  const [activeResult, setActiveResult] = useState(null)
  const [isCopied, setIsCopied] = useState(false)
  const [copiedLink, setCopiedLink] = useState('')
  const [isDesktop, setIsDesktop] = useState(false)

  function formatPercent(decimal) {
    return (decimal * 100).toFixed(2) + '%'
  }

  useEffect(() => {
    width > 768 ? setIsDesktop(true) : setIsDesktop(false)
  }, [height, width])

  return (
    <Layout>
      <div className='synergyContainer'>
        <div className='row row1'>
          {userExists === null && (
            <>
              <span className='title t1'>Open to Networking?</span>
              <span className='title t2'>
                Use <span className='greenGradient'> your FirstBatch ID</span>{' '}
                to match with people in WebSummit
              </span>
              <span className='title smolText'>
                We&apos;re using a signed message as a seed to create your
                decentralized ID. FirstBatch uses zero-knowledge cryptography to
                preserve your privacy.
              </span>
              <span className='text t3'>Start by adding your Telegram ID</span>
              <form
                className='inputContainer'
                onSubmit={e => {
                  e.preventDefault()
                  if (isPending || matches !== null) return

                  if (telegramId === '') {
                    setFormError({
                      isError: true,
                      errorMessage: 'Please provide a @username!',
                    })
                    setTimeout(() => {
                      setFormError({ isError: false, errorMessage: '' })
                    }, 5000)
                  } else {
                    createIdentity({ telegramId, type: 'init' })
                  }
                }}>
                <input
                  className={
                    formError.isError
                      ? 'inputErr'
                      : telegramId !== ''
                      ? 'activeInput'
                      : ''
                  }
                  value={telegramId}
                  placeholder={
                    formError.errorMessage === ''
                      ? '@username'
                      : formError.errorMessage
                  }
                  onChange={e => {
                    const inputLength = e.target.value.length
                    const input = e.target.value

                    inputLength === 1 &&
                    input !== '@' &&
                    formError.isError === false
                      ? setTelegramId(`@${input}`)
                      : formError.isError === false && setTelegramId(input)
                  }}
                  type='text'
                />
                <button>{isPending ? <Puff /> : 'Join'}</button>
              </form>
              <div className='line1'></div>
            </>
          )}
          {errMsg !== '' && <span>{errMsg}</span>}
          {userExists === true
            ? matches !== null &&
              (matches.length !== 0 ? (
                <div className='resultsContainer'>
                  <div className='p'>
                    Simply add people to your Telegram and start chatting! You
                    can be invisible anytime you want.
                  </div>
                  <div className='checkboxContainer'>
                    <span>Visibility</span>
                    {isVisible !== null && (
                      <div
                        className={`checkBox ${isVisible ? '' : 'deactivated'}`}
                        onClick={() => switchVisibility()}>
                        <div className='dot'></div>
                      </div>
                    )}
                  </div>
                  <div className='results'>
                    <div className='topbar'>
                      <div className='c1'></div>
                      <div className='c2'>Telegram ID</div>
                      <div className='c3'>Synergy</div>
                      <div className='c4'></div>
                      {/* Matched Groups & Traits */}
                    </div>
                    {matches.map((result, index) => {
                      return (
                        <div
                          className={`result ${
                            activeResult === result ? 'activeResult' : ''
                          }`}
                          key={`result${index}`}>
                          <div
                            className='top'
                            onClick={() => {
                              activeResult === result
                                ? setActiveResult(null)
                                : setActiveResult(result)
                            }}>
                            <div className='c1'>
                              {isCopied && copiedLink === `@${result[0]}` ? (
                                <IoCheckmark />
                              ) : (
                                <VscCopy
                                  onClick={() => {
                                    if (isCopied) return
                                    navigator.clipboard.writeText(
                                      `@${result[0]}`
                                    )
                                    setIsCopied(true)
                                    setCopiedLink(`@${result[0]}`)
                                    setTimeout(() => {
                                      setIsCopied(false)
                                      setCopiedLink('')
                                    }, 5000)
                                  }}
                                />
                              )}
                            </div>
                            <div className='c2'>@{result[0]}</div>
                            <div className='c3'>
                              {formatPercent(result[1].score)}
                            </div>
                            <div className='c4'>
                              {/* {Object.entries(result[1].matched_traits)
                                .slice(0, maxTrait)
                                .map((matchedTrait, index) => {
                                  return (
                                    <span key={`matchedTrait${index}`}>
                                      {matchedTrait[0]}
                                    </span>
                                  )
                                })} */}
                              {isDesktop && (
                                <>
                                  <span>
                                    Matched Traits (
                                    {
                                      Object.entries(result[1].matched_traits)
                                        .length
                                    }
                                    )
                                  </span>
                                  <MdKeyboardArrowDown
                                    className={`${
                                      activeResult === result ? 'rotated' : ''
                                    }`}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className='bottom'>
                            <div className='label'>Traits</div>
                            <div className='scrollContainer'>
                              {Object.entries(result[1].matched_traits).map(
                                (matchedTrait, index) => {
                                  return (
                                    <span key={`matchedTrait${index}`}>
                                      {matchedTrait[0]}
                                    </span>
                                  )
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ) : (
                <>There is no interest detected</>
              ))
            : userExists === false && (
                <div className='userNotFound'>
                  <h3>
                    Please create your FirstBatch ID <br /> and connect socials
                    to your personhood.
                  </h3>
                  <a
                    href='https://user.firstbatch.xyz/'
                    target='_blank'
                    className='mx-auto'
                    rel='noreferrer'>
                    <button>Create FirstBatch Identity</button>
                  </a>
                </div>
              )}
        </div>
      </div>
    </Layout>
  )
}
